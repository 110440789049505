.small-button {
  background-color: #f0ede0; /* Light beige background */
  color: black; /* Black text */
  border: none; /* No border */
  padding: 0px 8px; /* Padding around text and icon */
  font-size: 12px; /* Text size */
  display: inline-flex; /* For aligning icon and text */
  align-items: center; /* Vertical alignment */
  font-weight: 400;
  vertical-align: middle;
  justify-content: center; /* Horizontal alignment */
  height: 28px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 8px;
  margin-left: 8px;
}

.small-button-icon {
  height: 13px;
  width: 13px;
  filter: invert(100%);
  margin-right: 4px;
}
.small-button-icon.icon-inverted,
.small-button-icon-outline.icon-inverted {
  filter: invert(1);
}

.small-button-outline {
  background-color: transparent; /* Light beige background */
  color: #f0ede0;
  border: 1px solid #f0ede0; /* No border */
  padding: 0px 8px; /* Padding around text and icon */
  font-size: 12px; /* Text size */
  display: inline-flex; /* For aligning icon and text */
  align-items: center; /* Vertical alignment */
  font-weight: 400;
  vertical-align: middle;
  justify-content: center; /* Horizontal alignment */
  height: 28px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 8px;
  margin-left: 8px;
}

.small-button-icon-outline {
  height: 13px;
  width: 13px;
  margin-right: 4px;
}

.small-button-outline-danger {
  border: 1px solid #a10303;
}
