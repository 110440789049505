.maintenance-tab-container {
}

.maintenance-tab-container .tab-content {
  background: #1c1c1c;
}

.button-group {
  display: flex;
  gap: 8px;
}
.button-group button {
  margin: 0;
}

.maintenance-active td {
  color: #ed4f4f;
}
